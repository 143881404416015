<template>
  <v-container class="reserve">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title>接種日期間隔查詢</v-card-title>
      <v-card-text>
        <v-select
          class="mb-4"
          v-model="option.completed"
          :items="[
            { text: '未接種過', value: '0' },
            { text: '已接種一次', value: '1' },
            { text: '已接種兩次', value: '2' },
            { text: '已接種三次', value: '3' },
          ]"
          label="已接種次數"
          outlined
          required
          hide-details
          dense
        ></v-select>
        <v-select
          class="mb-4"
          v-model="option.prevVaccine"
          :items="[
            { text: 'AZ', value: 'AZ' },
            { text: 'BNT', value: 'BN' },
            { text: '莫德納', value: 'MO' },
            { text: '高端', value: 'MV' },
          ]"
          label="前次接種疫苗"
          outlined
          required
          hide-details
          :disabled="!option.completed"
          dense
        ></v-select>
        <v-menu
          v-model="modal"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mb-4"
              v-model="option.prevDate"
              label="前次接種日期"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details
              dense
              :disabled="!option.completed"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="zh-tw"
            v-model="option.prevDate"
            @input="modal = false"
          ></v-date-picker>
        </v-menu>
        <v-checkbox
          class="mt-2"
          v-model="option.teenage"
          label="12-17 歲青少年"
          hide-details
        ></v-checkbox>
        <v-checkbox
          class="mt-2"
          v-model="option.old"
          label="65 歲以上老年人"
          hide-details
        ></v-checkbox>
        <v-checkbox
          class="mt-2"
          v-model="option.abroad"
          label="緊急出國"
          hide-details
        ></v-checkbox>
        <v-checkbox
          class="mt-2"
          v-model="option.booster"
          label="免疫不全、免疫力低下族群"
          hide-details
        ></v-checkbox>
      </v-card-text>
    </v-card>

    <div v-if="result.message">
      <v-alert
        v-if="!result.success"
        text
        type="error"
        class="font-weight-bold"
      >
        {{ result.message }}
      </v-alert>
      <v-alert v-else text type="success" class="font-weight-bold">
        <div>可預約劑別：第 {{ result.message.dose }} 劑</div>
        <div>可預約日期：{{ result.message.date }} (含)以後</div>
        <div v-if="result.message.note">
          注意事項：{{ result.message.note }}
        </div>
      </v-alert>
    </div>

    <v-row class="mt-2">
      <v-col>
        <v-btn color="" @click="$router.push('/covid')" block x-large>
          <v-icon>mdi-arrow-u-left-top</v-icon> 返回
        </v-btn> </v-col
      ><v-col>
        <v-btn color="success" @click="check" block x-large>
          <v-icon>mdi-calendar-search</v-icon> 查詢
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "SelfCheck",
  data() {
    return {
      modal: false,
      option: {
        completed: null,
        prevDate: null,
        prevVaccine: null,
        abroad: false,
        teenage: false,
        booster: false,
      },
      result: {
        success: true,
        message: null,
      },
    };
  },
  async created() {},
  computed: {},
  methods: {
    async check() {
      this.$gtag.event("按鈕-查詢間隔");
      this.result = (
        await axios.post("/covid/getAvaliableDate", this.option)
      ).data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
