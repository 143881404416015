<template>
  <v-container class="home">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title>流感疫苗查詢</v-card-title>

      <v-card-text>
        <v-card-subtitle class="subtitle">行政區</v-card-subtitle>
        <v-row class="pb-6">
          <v-col
            class="py-0"
            cols="4"
            xs="3"
            sm="2"
            v-for="region of regions"
            :key="region"
          >
            <v-checkbox
              v-model="selectedRegions"
              :label="region"
              :value="region"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-card-subtitle class="subtitle">疫苗種類</v-card-subtitle>
        <v-row class="pb-6">
          <v-col
            class="py-0"
            cols="4"
            xs="3"
            sm="2"
            v-for="type of types"
            :key="type"
          >
            <v-checkbox
              v-model="selectedTypes"
              :label="type"
              :value="type"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-card-subtitle class="subtitle">場次日期</v-card-subtitle>
        <v-row>
          <v-col cols="6" sm="3">
            <v-menu
              v-model="dateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="selectedFromDate"
                  label="開始日期"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedFromDate"
                @input="dateFromMenu = false"
                locale="zh-hant"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="3">
            <v-menu
              v-model="dateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="selectedToDate"
                  label="結束日期"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedToDate"
                @input="dateToMenu = false"
                locale="zh-hant"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-card-subtitle class="subtitle">預約狀況</v-card-subtitle>
        <v-row>
          <v-col class="py-0">
            <v-checkbox
              v-model="showAvailable"
              label="僅顯示可預約"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-card-subtitle class="subtitle">場次結果</v-card-subtitle>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="filterList"
          no-data-text="暫無場次"
          :mobile-breakpoint="0"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.已預約="{ item }">
            {{ item.已預約 }} / {{ item.人數上限 }}
          </template>
          <template v-slot:item.開始時間="{ item }">
            {{ item.開始時間 }} ~ {{ item.結束時間 }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "Query",
  data: () => ({
    sections: [],
    selectedRegions: [],
    selectedTypes: [],
    selectedFromDate: null,
    selectedToDate: null,
    dateFromMenu: false,
    dateToMenu: false,
    showAvailable: false,
    headers: [
      // { text: "行政區", value: "行政區" },
      { text: "地點", value: "地點" },
      { text: "日期", value: "日期" },
      { text: "接種疫苗", value: "接種疫苗" },
      { text: "開始時間", value: "開始時間" },
      { text: "已預約", value: "已預約" },
    ],
  }),
  computed: {
    filterList() {
      let sections = this.sections;

      if (this.selectedRegions.length > 0) {
        sections = sections.filter((section) =>
          this.selectedRegions.includes(section.行政區)
        );
      }
      if (this.selectedTypes.length > 0) {
        sections = sections.filter((section) =>
          this.selectedTypes.includes(section.接種疫苗)
        );
      }
      if (this.selectedFromDate) {
        sections = sections.filter(
          (section) => dayjs(section.日期) >= dayjs(this.selectedFromDate)
        );
      }
      if (this.selectedToDate) {
        sections = sections.filter(
          (section) => dayjs(section.日期) <= dayjs(this.selectedToDate)
        );
      }

      if (this.showAvailable) {
        sections = sections.filter(
          (section) => section.已預約 < section.人數上限
        );
      }

      sections = _.orderBy(sections, ["日期", "開始時間"], ["asc", "asc"]);
      return sections;
    },
    regions() {
      return this.$store.state.regions;
    },
    types() {
      return this.$store.state.influenzaTypes;
    },
  },
  async created() {
    await this.getSections();
  },
  methods: {
    async getSections() {
      const res = await axios.get(`/influenza/section`);
      this.sections = res.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0px;
}
</style>
