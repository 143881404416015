<template>
  <router-view class="main" />
</template>

<script>
import axios from "axios";
import { ENV } from "../env.js";
export default {
  name: "App",

  data: () => ({
    CURRENT_VERSION: null,
  }),
  created() {
    this.CURRENT_VERSION = process.env.VUE_APP_VERSION;
    if (this.CURRENT_VERSION && ENV == "production") {
      this.checkVersion();
      setInterval(() => {
        this.checkVersion();
      }, 30 * 60 * 1000);
    }
  },
  methods: {
    async checkVersion() {
      let version = (await axios.get(`/version/${this.CURRENT_VERSION}`)).data
        .VERSION;
      if (version > this.CURRENT_VERSION) {
        location.reload(true);
      }
    },
  },
};
</script>
<style lang="scss" soped>
.v-main__wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  background-color: #c75213;
  color: white;
  line-height: 50px;
  font-size: 24px;
}
.main {
  // padding-bottom: 20px !important;
  flex: 1;
}
footer {
  width: 100%;
  background-color: #11161f;
  color: white;
  a {
    color: #fff !important;
  }
}
</style>
