import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: require("@/views/Entry.vue").default,
  },
  {
    path: "/covid",
    component: require("@/views/Covid/Template.vue").default,
    children: [
      {
        path: "",
        name: "CovidHome",
        component: require("@/views/Covid/Home.vue").default,
      },
      {
        path: "reserve",
        name: "CovidReserve",
        component: require("@/views/Covid/Reserve.vue").default,
      },
      {
        path: "my-reservation",
        name: "CovidMyReservation",
        component: require("@/views/Covid/MyReservation.vue").default,
        props: true,
      },
      {
        path: "check",
        name: "CovidCheck",
        component: require("@/views/Covid/Check.vue").default,
      },
      {
        path: "selfCheck",
        name: "CovidSelfCheck",
        component: require("@/views/Covid/SelfCheck.vue").default,
      },
    ],
  },
  {
    path: "/influenza",
    component: require("@/views/Influenza/Template.vue").default,
    children: [
      {
        path: "",
        name: "InfluenzaHome",
        component: require("@/views/Influenza/Home.vue").default,
      },
      {
        path: "query",
        name: "InfluenzaQuery",
        component: require("@/views/Influenza/Query.vue").default,
      },
      {
        path: "reserve",
        name: "InfluenzaReserve",
        component: require("@/views/Influenza/Reserve.vue").default,
      },
      {
        path: "my-reservation",
        name: "InfluenzaMyReservation",
        component: require("@/views/Influenza/MyReservation.vue").default,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
