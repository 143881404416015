<template>
  <v-app>
    <v-main class="app">
      <header class="text-center mb-2">台南 COVID-19 疫苗接種預約系統</header>
      <router-view class="main" />
      <footer>
        <v-container>
          <div>其他資訊：</div>
          <div>
            <ul>
              <li>
                COVID-19疫苗接種服務之院所：
                <a
                  target="_blank"
                  href="https://health.tainan.gov.tw/page.asp?mainid=41775F14-02A6-419A-9A0B-C3F6E2441223"
                  >https://health.tainan.gov.tw/page.asp?mainid=41775F14-02A6-419A-9A0B-C3F6E2441223</a
                >
              </li>
              <li>
                疫苗地圖：
                <a target="_blank" href="https://antiflu.cdc.gov.tw/Covid19#"
                  >https://antiflu.cdc.gov.tw/Covid19#</a
                >
              </li>
            </ul>
          </div>
        </v-container>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Covid",
  data: () => ({}),
  created() {},
  methods: {},
};
</script>
<style lang="scss" soped>
.v-main__wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  background-color: #c75213;
  color: white;
  line-height: 50px;
  font-size: 24px;
}
.main {
  //   padding-bottom: 20px !important;
  flex: 1;
}
footer {
  width: 100%;
  background-color: #11161f;
  color: white;
  a {
    color: #fff !important;
  }
}
</style>
