<template>
  <v-container class="reservation">
    <v-card elevation="2" color="#fdfdfd">
      <v-card-title>我的預約</v-card-title>
      <v-card-text class="reservation-info">
        <div v-if="validReservation">
          <ol class="mb-6">
            <li>
              前往接種請戴口罩，並攜帶：
              <ul>
                <li>健保卡</li>
                <li>中華民國身分證</li>
              </ul>
            </li>
            <li>如若您未滿18歲，請務必有家長或監護人隨行，方能完成接種</li>
            <li>
              本預約服務僅協助保留針劑，並不確保您必然可以接種。現場需再次確認您的接種資格符合中央規定，並經醫生診斷同意後，使得接種
            </li>
            <li>若您個人行程異動，無法前往接種，請透過本系統修改場次或取消</li>
          </ol>

          <div>
            <div v-if="reservation.身分證字號" class="reservation-item">
              <div class="reservation-title">身分證字號：</div>
              <div class="reservation-content">
                {{ form.id }}
              </div>
            </div>
            <div v-if="reservation.日期" class="reservation-item">
              <div class="reservation-title">預約日期：</div>
              <div class="reservation-content">
                {{ reservation.日期.split(" ")[0] }}
              </div>
            </div>
            <div v-if="reservation.開始時間" class="reservation-item">
              <div class="reservation-title">場次時段：</div>
              <div class="reservation-content">
                {{ reservation.開始時間 }} ~ {{ reservation.結束時間 }}
              </div>
            </div>
            <div v-if="reservation.建議時間" class="reservation-item">
              <div class="reservation-title">建議報到時間：</div>
              <div class="reservation-content">
                {{ reservation.建議時間 }}
              </div>
            </div>
            <div v-if="reservation.地點" class="reservation-item">
              <div class="reservation-title">場次地點：</div>
              <div class="reservation-content">
                {{ reservation.地點 }}
              </div>
            </div>
            <div v-if="reservation.場次地址" class="reservation-item">
              <div class="reservation-title">場次地址：</div>
              <div class="reservation-content">
                {{ reservation.場次地址 }}
              </div>
            </div>
            <div v-if="reservation.電話" class="reservation-item">
              <div class="reservation-title">聯絡電話：</div>
              <div class="reservation-content">
                {{ reservation.電話 }}
              </div>
            </div>
          </div>

          <v-row class="mt-2">
            <v-col>
              <v-btn color="" @click="$router.push('/influenza')" block x-large>
                <v-icon>mdi-arrow-u-left-top</v-icon> 返回
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="error" @click="cancel" block x-large>
                <v-icon>mdi-calendar-refresh</v-icon> 取消預約
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          目前沒有預約紀錄
          <v-row class="mt-2">
            <v-col>
              <v-btn color="" @click="$router.push('/influenza')" block x-large>
                <v-icon>mdi-arrow-u-left-top</v-icon> 返回
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import ls from "expired-localstorage";
export default {
  name: "Reservation",
  props: ["toReserve"],
  data() {
    return {
      form: null,
      reservation: null,
    };
  },
  async created() {
    this.form = ls.get("form") || this.form;
    this.reservation = (
      await axios.post("/influenza/myReserve", this.form)
    ).data;
    if (this.toReserve && !this.validReservation) {
      this.$router.push("/influenza/reserve");
    }
  },
  computed: {
    validReservation() {
      return (
        this.reservation &&
        this.reservation.日期 &&
        dayjs().format("YYYY-MM-DD") <=
          dayjs(this.reservation.日期).format("YYYY-MM-DD")
      );
    },
  },
  methods: {
    async cancel() {
      let date = this.reservation.日期;
      if (dayjs() >= dayjs(date).add(-12, "hour")) {
        this.$toast.error("已超過最後取消時間，請等待場次結束後再次預約。");
      } else {
        if (confirm("確認取消接種預約？")) {
          try {
            await axios.post("/influenza/cancel", {
              身分證字號: this.form.id,
              出生年月日: this.form.birth,
            });
            this.$gtag.event("按鈕-取消預約");
            this.$toast.success("已取消接種預約");
            ls.remove("reservation");
            setTimeout(() => {
              this.$router.push("/influenza");
            }, 500);
          } catch (error) {
            this.$toast.error(error.response.data);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation-info {
  font-size: 16px;
}
.reservation-item {
  display: flex;
}
.reservation-title {
  flex: calc(50% - 10px);
  text-align: right;
  font-weight: bold;
}
.reservation-content {
  flex: calc(50% + 10px);
}

ol > li {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
ul > li {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
</style>
