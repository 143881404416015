<template>
  <v-app>
    <v-main class="app">
      <header class="text-center mb-2">台南疫苗接種預約系統</header>
      <v-container style="height: calc(100vh - 154px)">
        <v-layout fill-height>
          <v-row>
            <v-col>預約 Covid 疫苗</v-col>
            <v-col>預約流感疫苗</v-col>
          </v-row>
        </v-layout>
      </v-container>
      <footer>
        <v-container>
          <div>其他資訊：</div>
          <div>
            <ul>
              <li>
                台南市政府：
                <a target="_blank" href="https://www.tainan.gov.tw/"
                  >https://www.tainan.gov.tw/</a
                >
              </li>
              <li>
                台南市衛生局：
                <a target="_blank" href="https://health.tainan.gov.tw/"
                  >https://health.tainan.gov.tw/</a
                >
              </li>
            </ul>
          </div>
        </v-container>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Entry",
  data: () => ({}),
  created() {
    this.$router.push("/covid");
  },
  methods: {},
};
</script>
<style lang="scss" soped>
.v-main__wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  background-color: #c75213;
  color: white;
  line-height: 50px;
  font-size: 24px;
}
.main {
  // padding-bottom: 20px !important;
  flex: 1;
}
footer {
  width: 100%;
  background-color: #11161f;
  color: white;
  a {
    color: #fff !important;
  }
}
</style>
