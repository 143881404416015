<template>
  <v-app>
    <v-main class="app">
      <header class="text-center mb-2">台南流感疫苗接種預約系統</header>
      <router-view class="main" />
      <footer>
        <v-container>
          <div>其他資訊：</div>
          <div>
            <ul>
              <ul>
                <li>
                  台南市政府：
                  <a target="_blank" href="https://www.tainan.gov.tw/"
                    >https://www.tainan.gov.tw/</a
                  >
                </li>
                <li>
                  台南市衛生局：
                  <a target="_blank" href="https://health.tainan.gov.tw/"
                    >https://health.tainan.gov.tw/</a
                  >
                </li>
              </ul>
            </ul>
          </div>
        </v-container>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Influenza",
  data: () => ({}),
  created() {},
  methods: {},
};
</script>
<style lang="scss" soped>
.v-main__wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  background-color: #c75213;
  color: white;
  line-height: 50px;
  font-size: 24px;
}
.main {
  //   padding-bottom: 20px !important;
  flex: 1;
}
footer {
  width: 100%;
  background-color: #11161f;
  color: white;
  a {
    color: #fff !important;
  }
}
</style>
