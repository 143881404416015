var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reserve"},[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',[_vm._v("選擇疫苗種類")]),(_vm.ready)?_c('v-card-text',{staticClass:"px-1"},[_c('VueRadioButton',{staticClass:"container-vaccine d-flex",attrs:{"options":_vm.vaccineTypes},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"btn-vaccine"},[_vm._v(_vm._s(props.text))])]}}],null,false,126942021),model:{value:(_vm.selected.vaccine),callback:function ($$v) {_vm.$set(_vm.selected, "vaccine", $$v)},expression:"selected.vaccine"}})],1):_vm._e()],1),(_vm.selected.vaccine)?_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v("選擇接種地點")]),_c('div',{staticClass:"reload",on:{"click":function($event){return _vm.refresh()}}},[_vm._v("[重新整理]")])]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.listRegions,"label":"請選擇行政區","hide-details":"","outlined":"","dense":""},model:{value:(_vm.selected.region),callback:function ($$v) {_vm.$set(_vm.selected, "region", $$v)},expression:"selected.region"}}),_c('v-select',{staticClass:"mt-4",attrs:{"placeholder":"請選擇","items":_vm.listHospitals,"no-data-text":"暫無接種地點","label":"請選擇接種地點","outlined":"","hide-details":"","dense":""},model:{value:(_vm.selected.hospital),callback:function ($$v) {_vm.$set(_vm.selected, "hospital", $$v)},expression:"selected.hospital"}})],1)],1):_vm._e(),(_vm.selected.hospital)?_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',[_vm._v("選擇接種時間")]),_c('v-card-text',[_c('div',{staticClass:"container-section"},_vm._l((_vm.listSections),function(section){return _c('div',{staticClass:"section text-center",class:{
            active: _vm.selected.section == section,
            disabled:
              section.已配發 >= section.配額上限 ||
              _vm.dayjs(_vm.validDate) > _vm.dayjs(section.日期) ||
              _vm.dayjs(_vm.releaseAt) > _vm.dayjs(section.日期),
          },on:{"click":function($event){return _vm.selectSection(section)}}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(section.日期))]),_vm._v(" "+_vm._s(section.開始時間)+"~"+_vm._s(section.結束時間)),_c('br'),_vm._v(" 尚可預約 "+_vm._s(section.配額上限 - section.已配發)+" 位 "),(_vm.dayjs(_vm.validDate) > _vm.dayjs(section.日期))?_c('div',{staticClass:"text-subtitle-2 red--text text--darken-4"},[_vm._v(" 日期間隔不符 ")]):(_vm.dayjs(_vm.releaseAt) > _vm.dayjs(section.日期))?_c('div',{staticClass:"text-subtitle-2 red--text text--darken-4"},[_vm._v(" 確診需間隔三個月 ")]):_vm._e()])}),0)])],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-btn',{attrs:{"color":"","block":"","x-large":""},on:{"click":function($event){return _vm.$router.push('/covid')}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" 返回 ")],1)],1),_c('v-col',[_c('v-btn',{key:_vm.reserveKey,attrs:{"color":"success","block":"","x-large":"","disabled":!_vm.selected.section},on:{"~click":function($event){return _vm.reserve.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" 確認預約 ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }