<template>
  <v-container class="reservation">
    <v-card elevation="2" color="#fdfdfd">
      <v-card-title>我的預約</v-card-title>
      <v-card-text class="reservation-info">
        <div v-if="validReservation">
          <ol class="mb-6">
            <li>
              前往接種請戴口罩，並攜帶：
              <ul>
                <li>健保卡</li>
                <li>中華民國身分證</li>
                <li>疫苗接種記錄卡(小黃卡)，接種第一劑者無需攜帶</li>
              </ul>
            </li>
            <li>如若您未滿18歲，請務必有家長或監護人隨行，方能完成接種</li>
            <li>
              若您已滿18歲，但尚未年滿20歲，得以經家長或監護人簽名之
              <span class="link" @click="toAgreement()">接種意願書</span> （
              https://www.cdc.gov.tw/Category/Page/4P2oowuEp0CoDlob356s8g
              ），替代家長或監護人陪同
            </li>
            <li>若您預約接種基礎加強劑，請務必自備身份證明文件</li>
            <li>
              本預約服務僅協助保留針劑，並不確保您必然可以接種。現場需再次確認您的接種資格符合中央規定，並經醫生診斷同意後，使得接種
            </li>
            <li>若您個人行程異動，無法前往接種，請透過本系統修改場次或取消</li>
          </ol>

          <div>
            <div v-if="reservation.接種時間" class="reservation-item">
              <div class="reservation-title">身分證字號：</div>
              <div class="reservation-content">
                {{ form.id }}
              </div>
            </div>
            <div v-if="reservation.接種劑別" class="reservation-item">
              <div class="reservation-title">接種劑別：</div>
              <div class="reservation-content">
                {{ dose }}
              </div>
            </div>
            <div v-if="reservation.接種疫苗" class="reservation-item">
              <div class="reservation-title">接種疫苗：</div>
              <div class="reservation-content">
                {{ reservation.接種疫苗 }}
              </div>
            </div>
            <div v-if="reservation.接種時間" class="reservation-item">
              <div class="reservation-title">接種日期：</div>
              <div class="reservation-content">
                {{ reservation.接種時間.split(" ")[0] }}
              </div>
            </div>
            <div v-if="reservation.接種時段" class="reservation-item">
              <div class="reservation-title">接種時段：</div>
              <div class="reservation-content">
                {{ reservation.接種時段 }}
              </div>
            </div>
            <div v-if="reservation.接種時間" class="reservation-item">
              <div class="reservation-title">建議報到時間：</div>
              <div class="reservation-content">
                {{ reservation.接種時間.split(" ")[1] }}
              </div>
            </div>
            <div v-if="reservation.接種地點" class="reservation-item">
              <div class="reservation-title">接種地點：</div>
              <div class="reservation-content">
                {{ reservation.接種地點 }}
              </div>
            </div>
            <div v-if="reservation.接種地址" class="reservation-item">
              <div class="reservation-title">接種地址：</div>
              <div class="reservation-content">
                {{ reservation.接種地址 }}
              </div>
            </div>
            <div v-if="reservation.接種電話" class="reservation-item">
              <div class="reservation-title">聯絡電話：</div>
              <div class="reservation-content">
                {{ reservation.接種電話 }}
              </div>
            </div>
          </div>

          <v-row class="mt-2">
            <v-col>
              <v-btn color="" @click="$router.push('/covid')" block x-large>
                <v-icon>mdi-arrow-u-left-top</v-icon> 返回
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="error" @click="cancel" block x-large>
                <v-icon>mdi-calendar-refresh</v-icon> 取消預約
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          目前沒有預約紀錄
          <v-row class="mt-2">
            <v-col>
              <v-btn color="" @click="$router.push('/covid')" block x-large>
                <v-icon>mdi-arrow-u-left-top</v-icon> 返回
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import ls from "expired-localstorage";
export default {
  name: "Reservation",
  props: ["propsReservation"],
  data() {
    return {
      form: null,
      reservation: null,
    };
  },
  async created() {
    this.form = ls.get("form") || this.form;
    this.reservation =
      this.propsReservation || (await axios.get("/covid/reserve")).data;
  },
  computed: {
    validReservation() {
      return this.reservation && this.reservation.接種時間;
    },
    dose() {
      switch (this.reservation.接種劑別) {
        case 1:
        case "1":
          return "第 1 劑";
          break;
        case 2:
        case "2":
          return "第 2 劑";
          break;
        case 3:
        case "3":
          return "基礎加強劑（需自備身份證明文件）";
          break;
        case "B":
          return "第 3 劑（追加劑第 1 劑）";
          break;
        case "B2":
          return "第 4 劑（追加劑第 2 劑）";
          break;
        case "B3":
          return "第 5 劑（追加劑第 3 劑）";
          break;
        case "B4":
          return "第 6 劑（追加劑第 4 劑）";
          break;
        case "B5":
          return "第 7 劑（追加劑第 5 劑）";
          break;
      }
    },
  },
  methods: {
    async cancel() {
      let date = this.reservation.接種時間.split(" ")[0];
      if (dayjs() >= dayjs(date).add(-12, "hour")) {
        this.$toast.error("已超過最後取消時間，請等待場次結束後再次預約。");
      } else {
        if (confirm("確認取消接種預約？")) {
          this.$gtag.event("按鈕-取消預約");
          await axios.delete("/covid/reserve");
          this.$toast.success("已取消接種預約");
          ls.remove("reservation");
          setTimeout(() => {
            this.$router.push("/covid");
          }, 500);
        }
      }
    },
    toAgreement() {
      window.open(
        "https://www.cdc.gov.tw/Category/Page/4P2oowuEp0CoDlob356s8g",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation-info {
  font-size: 16px;
}
.reservation-item {
  display: flex;
}
.reservation-title {
  flex: calc(50% - 10px);
  text-align: right;
  font-weight: bold;
}
.reservation-content {
  flex: calc(50% + 10px);
}

ol > li {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
ul > li {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
</style>
