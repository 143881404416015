<template>
  <v-container class="text-center">
    <v-row>
      <v-col>
        <div class="text-h6">您目前所在的場為</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h4">
          {{ params.reserveDate }}
          <br />
          {{ params.reservePlace }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-body">請輸入身分證字號和生日查詢是否有預約</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-text-field
          v-model="params.id"
          label="身分證字號"
          outlined
          persistent-hint
          hint="身分證字號共 10 碼"
        ></v-text-field>
      </v-col>
      <v-col :cols="12">
        <v-text-field
          v-model="params.birth"
          label="出生年月日"
          outlined
          persistent-hint
          hint="民國生日 7 碼，35 年 04 月 11 日，請輸入 0350411"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn x-large block color="success" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="res">
      <v-col>
        <div v-if="res.result">
          <div class="text-h5 success--text mt-2">
            您有完成預約，預約時間為<br />
            {{ res.result }}
          </div>
          <div class="text-body-1 success--text mt-1">
            (注意！報到 10 分鐘前才會開放入場)
          </div>
        </div>

        <div
          v-else
          class="text-h6 text-left error--text ml-4 font-weight-medium"
        >
          不在今日預約名單內，請檢查：
          <ol>
            <li>輸入資料是否正確。</li>
            <li>是否能提供預約資訊畫面。</li>
            <!-- <li>請聯繫您所屬單位名單負責人。</li> -->
          </ol>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Check",
  data: () => ({
    params: {
      reservePlace: "",
      reserveDate: "",
      id: "",
      birth: "",
    },

    res: null,
  }),
  created() {
    this.params.reservePlace = this.$route.query.place;
    this.params.reserveDate = this.$route.query.date;
  },
  methods: {
    async search() {
      if (
        !this.params.id ||
        !this.params.birth ||
        !this.params.reservePlace ||
        !this.params.reserveDate
      ) {
        alert("輸入資訊有錯誤！");
        return false;
      }
      this.res = (
        await axios.get(`/covid/isReserve`, { params: this.params })
      ).data;
    },
  },
};
</script>
<style lang="scss">
// .v-text-field input {
//   font-size: 1.3em;
//   text-align: center;
// }
</style>
