<template>
  <v-container class="reserve">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title class="d-flex justify-space-between">
        <div>選擇接種地點</div>
        <div class="reload" @click="refresh()">[重新整理]</div>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selected.行政區"
          :items="listRegions"
          label="請選擇行政區"
          hide-details
          outlined
          dense
        ></v-select>
        <v-select
          class="mt-4"
          placeholder="請選擇"
          v-model="selected.地點"
          :items="listHospitals"
          no-data-text="暫無預約地點"
          label="請選擇預約地點"
          outlined
          hide-details
          dense
        ></v-select>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd" class="mb-4" v-if="selected.地點">
      <v-card-title>選擇預約時間</v-card-title>
      <v-card-text>
        <div class="container-section">
          <div
            class="section text-center"
            :class="{
              active: selected.section && selected.section._id == section._id,
              disabled: section.已預約 >= section.人數上限,
            }"
            v-for="section in listSections"
            @click="selectSection(section)"
          >
            <div class="font-weight-bold">{{ section.日期 }}</div>
            {{ section.開始時間 }}~{{ section.結束時間 }}<br />
            尚可預約 {{ section.人數上限 - section.已預約 }} 位
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="" @click="$router.push('/influenza')" block x-large>
          <v-icon>mdi-arrow-u-left-top</v-icon> 返回
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="success"
          @click.once="reserve"
          :key="reserveKey"
          block
          x-large
          :disabled="!selected.section"
        >
          <v-icon>mdi-check</v-icon> 確認預約
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import VueRadioButton from "vue-radio-button";

export default {
  name: "Reserve",
  components: { VueRadioButton },
  data() {
    return {
      ready: false,
      form: null,
      selected: null,
      available: [],
      reserveKey: 0,
    };
  },
  async created() {
    setTimeout(() => {
      this.$vloading.hide();
      this.ready = true;
    }, 1500);
    this.form = ls.get("form");
    this.resetSelected();
    await this.getAvailable();
    this.$vloading.show();
  },
  computed: {
    age() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "years");
    },
    ageMonth() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "months");
    },
    birthDate() {
      let birth = parseInt(this.form.birth) + 19110000;
      let month = String(birth).slice(4, 6);
      let day = String(birth).slice(6, 8);
      return dayjs().get("year") + `-${month}-${day}`;
    },

    dayjs() {
      return dayjs;
    },
    listSections() {
      let list = this.available.filter((section) => {
        return section.地點 == this.selected.地點;
      });
      list = _.sortBy(list, ["日期", "開始時間"]);
      return list;
    },
    listRegions() {
      let list = this.available;
      let availableRegions = Object.keys(
        _.groupBy(list, (section) => section.行政區)
      );
      availableRegions = availableRegions.map((region) => ({
        text: region,
        value: region,
      }));
      availableRegions.unshift({
        text: "台南市全區",
        value: null,
      });
      return availableRegions;
    },
    listHospitals() {
      let list = this.available.filter((hospital) =>
        this.selected.行政區 ? hospital.行政區 == this.selected.行政區 : true
      );

      let hospitals = _(list)
        .groupBy("地點")
        .map((hospital, 地點) => ({
          text: `${hospital[0].行政區} - ${
            hospital[0].地點
          } (尚可預約 ${_.sumBy(hospital, "人數上限") -
            _.sumBy(hospital, "已預約")} 位)`,
          value: 地點,
          left: _.sumBy(hospital, "人數上限") - _.sumBy(hospital, "已預約"),
        }))
        .value();
      hospitals = _.orderBy(hospitals, ["left"], ["desc"]);
      return hospitals;
    },
  },
  methods: {
    resetSelected() {
      this.selected = {
        section: null,
        行政區: null,
        地點: null,
        場次: null,
      };
    },
    async refresh() {
      this.$gtag.event("按鈕-重新整理");
      await this.getAvailable();
    },
    async getAvailable() {
      let available = (await axios.get("/influenza/section")).data;
      this.available = available.map((section) => {
        section.已預約 =
          section.已預約 > section.人數上限 ? section.人數上限 : section.已預約;
        return section;
      });
    },
    selectSection(section) {
      if (section.已預約 >= section.人數上限) {
        return;
      }
      this.selected.section = section;
    },
    async reserve() {
      let { _id } = this.selected.section;
      let result = (await axios.post(`/influenza/reserve/${_id}`, this.form))
        .data;
      if (result.日期) {
        this.$toast.success("已成功預約接種！");
        setTimeout(() => {
          this.$router.push({
            name: "InfluenzaMyReservation",
          });
        }, 500);
      } else {
        this.$toast.error("預約接種失敗，請選擇時他時段！");
        this.selected.section = null;
        this.getAvailable();
        this.reserveKey++;
      }
    },
  },
  watch: {
    "selected.行政區": function() {
      this.selected.場次 = null;
    },
    "selected.地點": function() {
      this.selected.場次 = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-vaccine {
  text-align: center;
  // display: flex;
}
::v-deep .v-radio-label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 18%;
  height: 4em;
  margin: 1%;
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  transition: border-color 300ms;
  &:hover {
    border-color: #c75213;
  }
}

::v-deep .v-radio-active {
  border-color: #c75213;
  background: #c75213;
  color: #fff;
  font-weight: 600;
}
.container-section {
  display: flex;
  flex-wrap: wrap;
}
.section {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 150px;
  max-width: calc(50% - 10px);
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  padding: 20px 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 300ms;
  &:hover {
    border: 2px solid #c75213;
  }
  &.active {
    border-color: #c75213;
    background: #c75213;
    color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
    background: rgb(208, 208, 208);
    &:hover {
      border: 2px solid rgb(208, 208, 208);
    }
  }
}
.reload {
  cursor: pointer;
  font-size: 14px;
  color: #999;
  transition: color 300ms;
  &:hover {
    color: #c75213;
  }
}
</style>
