<template>
  <v-container class="reserve">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title>選擇疫苗種類</v-card-title>
      <v-card-text class="px-1" v-if="ready">
        <VueRadioButton
          v-model="selected.vaccine"
          :options="vaccineTypes"
          class="container-vaccine d-flex"
        >
          <template #label="{ props }">
            <div class="btn-vaccine">{{ props.text }}</div>
          </template>
        </VueRadioButton>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd" class="mb-4" v-if="selected.vaccine">
      <v-card-title class="d-flex justify-space-between">
        <div>選擇接種地點</div>
        <div class="reload" @click="refresh()">[重新整理]</div>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selected.region"
          :items="listRegions"
          label="請選擇行政區"
          hide-details
          outlined
          dense
        ></v-select>
        <v-select
          class="mt-4"
          placeholder="請選擇"
          v-model="selected.hospital"
          :items="listHospitals"
          no-data-text="暫無接種地點"
          label="請選擇接種地點"
          outlined
          hide-details
          dense
        ></v-select>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd" class="mb-4" v-if="selected.hospital">
      <v-card-title>選擇接種時間</v-card-title>
      <v-card-text>
        <div class="container-section">
          <div
            class="section text-center"
            :class="{
              active: selected.section == section,
              disabled:
                section.已配發 >= section.配額上限 ||
                dayjs(validDate) > dayjs(section.日期) ||
                dayjs(releaseAt) > dayjs(section.日期),
            }"
            v-for="section in listSections"
            @click="selectSection(section)"
          >
            <div class="font-weight-bold">{{ section.日期 }}</div>
            {{ section.開始時間 }}~{{ section.結束時間 }}<br />
            尚可預約 {{ section.配額上限 - section.已配發 }} 位
            <div
              class="text-subtitle-2 red--text text--darken-4"
              v-if="dayjs(validDate) > dayjs(section.日期)"
            >
              日期間隔不符
            </div>
            <div
              class="text-subtitle-2 red--text text--darken-4"
              v-else-if="dayjs(releaseAt) > dayjs(section.日期)"
            >
              確診需間隔三個月
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="" @click="$router.push('/covid')" block x-large>
          <v-icon>mdi-arrow-u-left-top</v-icon> 返回
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="success"
          @click.once="reserve"
          :key="reserveKey"
          block
          x-large
          :disabled="!selected.section"
        >
          <v-icon>mdi-check</v-icon> 確認預約
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import VueRadioButton from "vue-radio-button";

export default {
  name: "Reserve",
  components: { VueRadioButton },
  data() {
    return {
      ready: false,
      form: null,
      selected: null,
      validDate: null,
      releaseAt: null,
      rawAvailable: [],
      typeOnly: [],
      reserveKey: 0,
    };
  },
  async created() {
    this.form = ls.get("form");

    this.$vloading.show();
    setTimeout(() => {
      this.$vloading.hide();
      this.ready = true;
    }, 1500);

    this.resetSelected();
    await this.getValidDate();
    await this.getAvailable();

    // if (dayjs(this.validDate) > dayjs()) {
    //   this.$toast.error(`您能施打的日期為 ${this.validDate} 以後，目前尚未能預約！`);
    //   this.$router.push("/covid");
    // }
    // console.log(this.age);
  },
  computed: {
    available() {
      let available = [...this.rawAvailable];

      // special case
      let 特別場 =
        [
          "B",
          "BS",
          "B2",
          "B2S",
          "B3",
          "B3S",
          "B4",
          "B4S",
          "B5",
          "B5S",
        ].includes(this.form.dose) ||
        (this.age < 12 && this.age >= 5);

      if (
        this.selected.vaccine == "CoV_Moderna_Kid" ||
        this.selected.vaccine == "CoV_BioNTech_Baby"
      ) {
        特別場 = 0;
      }

      // if (特別場) {
      //   available = available.filter((section) => section.特別場);
      // } else {
      //   available = available.filter(
      //     (section) => section.特別場 == false || section.特別場 == undefined
      //   );
      // }
      available = available.filter((section) => this.age >= section.年齡限制);

      return available;
    },
    age() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "years");
    },
    ageMonth() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "months");
    },
    birthDate() {
      let birth = parseInt(this.form.birth) + 19110000;
      let month = String(birth).slice(4, 6);
      let day = String(birth).slice(6, 8);
      return dayjs().get("year") + `-${month}-${day}`;
    },
    vaccineTypes() {
      let types = [
        {
          id: "CoV_Moderna_XBB",
          text: "莫德納XBB",
        },
        {
          id: "CoV_Novavax_XBB",
          text: "Novavax XBB",
        },
      ];
      if (!this.form.dose) {
        return [];
      }
      // console.log(this.age);
      // console.log(this.ageMonth);

      let birth = parseInt(this.form.birth);
      switch (true) {
        case this.age >= 65 && this.form.dose == "2":
          types = types.filter((type) =>
            ["CoV_Novavax_XBB", "CoV_Moderna_XBB"].includes(type.id)
          );
          break;
        case this.age >= 12 && this.form.dose == "2":
          types = types.filter((type) => ["CoV_Novavax_XBB"].includes(type.id));
          break;
        case this.age < 5 && this.form.dose == "2":
          types = types.filter((type) => ["CoV_Moderna_XBB"].includes(type.id));
          break;
        case this.age < 12:
          types = types.filter((type) => ["CoV_Moderna_XBB"].includes(type.id));
          break;
        //   case this.ageMonth < 6:
        //     types = [];
        //     break;
        //   case this.age < 5:
        //     types = types.filter((type) =>
        //       [
        //         // "CoV_Moderna_Kid",
        //         "CoV_BioNTech_Baby",
        //         "CoV_bModerna_BA4/5",
        //         "CoV_bModerna_BA4/5-Booster",
        //       ].includes(type.id)
        //     );
        //     break;
        //   case this.age < 6:
        //     types = types.filter((type) =>
        //       [
        //         "CoV_BioNTech_Kid",
        //         // "CoV_Moderna_Kid",
        //         "CoV_bModerna_BA4/5",
        //         "CoV_bModerna_BA4/5-Booster",
        //       ].includes(type.id)
        //     );
        //     break;
        //   case this.age < 12:
        //     types = types.filter((type) =>
        //       [
        //         "Novavax",
        //         "CoV_Moderna_Kid",
        //         "CoV_BioNTech_Kid",
        //         "CoV_bModerna_BA4/5",
        //       ].includes(type.id)
        //     );
        //     break;
        //   case this.age < 18:
        //     types = types.filter((type) =>
        //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
        //     );
        //     break;
        //   case this.age < 20:
        //     types = types.filter((type) =>
        //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
        //     );
        //     break;
        //   case this.age < 65:
        //     types = types.filter((type) =>
        //       ["CoV_Novavax", "CoV_bModerna_BA1", "CoV_bModerna_BA4/5"].includes(
        //         type.id
        //       )
        //     );
        //     break;
        //   case this.age >= 65:
        //     types = types.filter((type) =>
        //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
        //     );
        //     break;
      }
      // console.log(types);

      // switch (true) {
      //   case (this.form.dose == "1" || this.form.dose == "2") && this.age < 5:
      //     types = types.filter((type) =>
      //       ["CoV_BioNTech_Baby", "CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      //   case (this.form.dose == "1" || this.form.dose == "2") && this.age < 6:
      //     types = types.filter((type) =>
      //       [
      //         "CoV_BioNTech_Kid",
      //         // "CoV_Moderna_Kid",
      //         "CoV_bModerna_BA4/5",
      //       ].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "1" && this.age < 12:
      //     types = types.filter((type) =>
      //       [
      //         "CoV_BioNTech_Kid",
      //         "CoV_Moderna_Kid",
      //         "CoV_bModerna_BA4/5",
      //       ].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "1" && this.age < 18:
      //     types = types.filter((type) =>
      //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "1":
      //     types = types.filter(
      //       (type) =>
      //         ![
      //           "CoV_bModerna",
      //           "CoV_BioNTech_Baby",
      //           "CoV_bModerna_BA1",
      //         ].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "2":
      //     types = types.filter(
      //       (type) => !["CoV_bModerna", "CoV_bModerna_BA1"].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "3" && this.age >= 12:
      //     types = types.filter((type) =>
      //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "3" && this.age < 12 && this.age >= 6:
      //     types = types.filter((type) =>
      //       ["CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "B" && this.age < 5:
      //     types = types.filter((type) =>
      //       ["CoV_BioNTech_Baby", "CoV_bModerna_BA4/5-Booster"].includes(
      //         type.id
      //       )
      //     );
      //     break;
      //   case (this.form.dose == "B" || this.form.dose == "B2") &&
      //     this.age < 6 &&
      //     this.age >= 5:
      //     types = types.filter((type) =>
      //       ["CoV_bModerna_BA4/5-Booster"].includes(type.id)
      //     );
      //     break;
      //   case (this.form.dose == "B" || this.form.dose == "B2") &&
      //     this.age < 12 &&
      //     this.age >= 6:
      //     types = types.filter((type) =>
      //       ["CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "B" && this.age >= 12 && this.age <= 17:
      //     types = types.filter((type) =>
      //       [
      //         "CoV_Novavax",
      //         // "CoV_BioNTech",
      //         "CoV_bModerna_BA1",
      //         "CoV_bModerna_BA4/5",
      //       ].includes(type.id)
      //     );
      //     break;
      //   case this.form.dose == "B2" && this.age >= 12:
      //   case this.form.dose == "B3":
      //   case this.form.dose == "B3S":
      //   case this.form.dose == "B4":
      //   case this.form.dose == "B4S":
      //   case this.form.dose == "B5":
      //   case this.form.dose == "B5S":
      //   case this.form.dose == "B2":
      //   case this.form.dose == "B2S":
      //   case this.form.dose == "BS":
      //     types = types.filter((type) =>
      //       ["CoV_Novavax", "CoV_bModerna_BA4/5"].includes(type.id)
      //     );
      //     break;
      // }

      // if (this.typeOnly.length) {
      //   types = types.filter((type) => this.typeOnly.includes(type.id));
      // }

      return types;
    },
    dayjs() {
      return dayjs;
    },
    listSections() {
      let list = this.available.filter((section) => {
        return (
          section.疫苗種類 == this.selected.vaccine &&
          section.名稱 == this.selected.hospital
        );
      });
      list = _.sortBy(list, ["日期", "開始時間"]);
      return list;
    },
    listRegions() {
      let list = this.available;
      list = list.filter(
        (hospital) => hospital.疫苗種類 == this.selected.vaccine
      );

      let availableRegions = Object.keys(
        _.groupBy(list, (section) => section.行政區)
      );
      availableRegions = availableRegions.map((region) => ({
        text: region,
        value: region,
      }));
      availableRegions.unshift({
        text: "台南市全區",
        value: null,
      });
      return availableRegions;
    },
    listHospitals() {
      let list = this.available.filter(
        (hospital) =>
          (this.selected.region
            ? hospital.行政區 == this.selected.region
            : true) && hospital.疫苗種類 == this.selected.vaccine
      );
      let hospitals = _(list)
        .groupBy("名稱")
        .map((hospital, 名稱) => ({
          text: `${hospital[0].行政區} - ${名稱} (尚可預約 ${_.sumBy(
            hospital,
            "配額上限"
          ) - _.sumBy(hospital, "已配發")} 位)`,
          value: 名稱,
          left: _.sumBy(hospital, "配額上限") - _.sumBy(hospital, "已配發"),
        }))
        .value();
      hospitals = _.orderBy(hospitals, ["left"], ["desc"]);
      return hospitals;
    },
  },
  methods: {
    resetSelected() {
      this.selected = {
        vaccine: null,
        region: null,
        hospital: null,
        section: null,
      };
    },
    async refresh() {
      this.$gtag.event("按鈕-重新整理");
      await this.getAvailable();
    },
    async getValidDate() {
      let result = (await axios.get("/covid/getValidDate")).data;
      if (result.message) {
        // console.log(result.message);
      }

      this.validDate = result.validDate || dayjs("YYYY-MM-DD");
      this.releaseAt = result.releaseAt;
      this.typeOnly = [];

      // this.validDate = this.validDate || "2022-01-11";
      if (
        this.selected.vaccine == "CoV_bModerna_BA4/5" &&
        this.form.dose == "2" &&
        result.prevDate
      ) {
        this.validDate = dayjs(result.prevDate)
          .add(this.age >= 6 ? 84 : 28, "day")
          .format("YYYY-MM-DD");
      }
    },
    async getAvailable() {
      let available = (
        await axios.get("/covid/available", { params: { dose: "all" } })
      ).data;

      this.rawAvailable = available.map((section) => {
        section.已配發 =
          section.已配發 > section.配額上限 ? section.配額上限 : section.已配發;
        return section;
      });
    },
    selectSection(section) {
      if (
        section.已配發 >= section.配額上限 ||
        dayjs(this.validDate) > dayjs(section.日期) ||
        dayjs(this.releaseAt) > dayjs(section.日期)
      ) {
        return;
      }

      this.selected.section = section;
    },
    async reserve() {
      let { 名稱, 日期, 開始時間, 結束時間 } = this.selected.section;
      let result = (
        await axios.post("/covid/reserve", {
          名稱,
          日期,
          開始時間,
          結束時間,
          劑別: this.form.dose,
        })
      ).data;
      if (result.接種時間) {
        this.$toast.success("已成功預約接種！");
        setTimeout(() => {
          this.$router.push({
            name: "CovidMyReservation",
            params: { propsReservation: result },
          });
        }, 500);
      } else {
        this.$toast.error("預約接種失敗，請選擇時他時段！");
        this.selected.section = null;
        this.getAvailable();
        this.reserveKey++;
      }
    },
  },
  watch: {
    "selected.vaccine": function(val) {
      if (val) this.$gtag.event(`疫苗-${val}`);
      this.selected.section = null;
      this.getValidDate();
    },
    "selected.region": function() {
      this.selected.section = null;
    },
    "selected.hospital": function() {
      this.selected.section = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-vaccine {
  text-align: center;
  // display: flex;
}
::v-deep .v-radio-label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 18%;
  height: 4em;
  margin: 1%;
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  transition: border-color 300ms;
  &:hover {
    border-color: #c75213;
  }
}

::v-deep .v-radio-active {
  border-color: #c75213;
  background: #c75213;
  color: #fff;
  font-weight: 600;
}
.container-section {
  display: flex;
  flex-wrap: wrap;
}
.section {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 150px;
  max-width: calc(50% - 10px);
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  padding: 20px 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 300ms;
  &:hover {
    border: 2px solid #c75213;
  }
  &.active {
    border-color: #c75213;
    background: #c75213;
    color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
    background: rgb(208, 208, 208);
    &:hover {
      border: 2px solid rgb(208, 208, 208);
    }
  }
}
.reload {
  cursor: pointer;
  font-size: 14px;
  color: #999;
  transition: color 300ms;
  &:hover {
    color: #c75213;
  }
}
</style>
