<template>
  <v-container class="home">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <!-- <v-card-title>接種對象實施期程</v-card-title>
      <v-card-text>
        <ol>
          <li>2023 年 9 月 26 日優先開放 65 歲以上民眾接種</li>
          <li>2023 年 10 月 2 日起再開放醫護人員接種</li>
          <li>暫定自 2023 年 10 月 9 日起開放全民接種</li>
        </ol>
      </v-card-text> -->
      <v-card-title>接種資格</v-card-title>
      <v-card-text class="list">
        <ol>
          <li>
            自 112 年 5 月 1 日起，自本市合約院所接種 COVID-19
            疫苗，將收取掛號費。
          </li>
          <li>若您曾為 COVID-19 確診個案，3 個月內請勿預約接種疫苗。</li>
          <li>
            自 112 年 9 月 26 日起，完成以下劑別者，則無須再預約接種任何疫苗。
            <ul>
              <li>12 歲以上已接種過 1 劑 Novavax XBB.1.5 者</li>
              <li>12 歲以上，未曾接種疫苗，以 2 劑 Novavax XBB.1.5 完成接種</li>
              <li>5 歲以上已接種過 1 劑莫德納 XBB.1.5 者</li>
              <li>
                已滿 6 個月至未滿 5 歲者，未曾接種疫苗，以 2 劑莫德納 XBB.1.5
                完成接種，或曾接種過 1 劑莫德納 XBB.1.5
              </li>
            </ul>
          </li>
          <li>
            接種第 1 劑 XBB.1.5：
            <ul>
              <li>12 歲以上未曾接種疫苗，得預約接種 Novavax XBB.1.5</li>
              <li>
                12 歲以上曾接種疫苗，與前 1 劑間隔 84 天以上，得預約接種 Novavax
                XBB.1.5
              </li>
              <li>
                5 歲以上未曾接種疫苗，得預約接種莫德納 XBB.1.5
              </li>
              <li>
                5 歲以上曾接種疫苗，與前 1 劑間隔 84 天以上，得預約接種莫德納
                XBB.1.5
              </li>
              <li>
                已滿 6 個月至未滿 5 歲者，未曾接種疫苗，得預約莫德納 XBB.1.5
              </li>
              <li>
                已滿 6 個月至未滿 5
                歲者，曾接種1劑單價或雙價莫德納疫苗基礎劑，與前 1 劑間隔 28
                天以上，得預約莫德納 XBB.1.5
              </li>
              <li>
                已滿 6 個月至未滿 5 歲者，曾接種 1 或 2 劑幼兒 BNT
                疫苗基礎劑，與前 1 劑間隔 28 天或 56 天，得預約接種莫德納
                XBB.1.5
              </li>
              <li>
                已滿 6 個月至未滿 5 歲者，已完成基礎劑接種，與前 1 劑間隔 84
                天以上，得預約接種莫德納 XBB.1.5
              </li>
            </ul>
          </li>
          <li>
            接種第 2 劑 XBB.1.5：
            <ul>
              <li>
                65 歲以上長者，完成 XBB.1.5 第 1 劑，間隔 84
                天以上，得預約接種莫德納 XBB.1.5 或 NovavaxXBB.1.5
              </li>
              <li>
                12 歲至 64 歲過去僅完成接種 1 劑 NovavaxXBB.1.5
                （未接種過原始株疫苗）者，間隔 28 天以上，得預約
                NovavaxXBB.1.5，預約方式請電洽轄區衛生所為您服務
              </li>
              <li>
                已滿 6 個月至未滿 5 歲者，曾接種 1 劑幼兒 BNT 疫苗基礎劑，完成第
                1 劑莫德納 XBB.1.5 接種後，間隔 56 天以上，得預約接種莫德納
                XBB.1.5
              </li>
              <li>
                滿 6
                個月以上且有免疫不全以及免疫力低下患者(請自備身份證明文件)，經醫師評估，完成
                XBB.1.5 第 1 劑，間隔 84 天以上，得接種莫德納 XBB.1.5 或
                NovavaxXBB.1.5，預約方式請電洽轄區衛生所為您服務
              </li>
            </ul>
          </li>
        </ol>
      </v-card-text>
      <v-card-title>注意事項</v-card-title>

      <v-card-text>
        <ul>
          <li>
            為讓特約醫事機構預留準備時間及核對名單，每場次最後可預約時間為接種日前
            1 日中午 12 點，之後將不開放預約與取消，僅供查詢。(舉例：2022/1/1
            場次，僅開放預約和取消至 2021/12/31 中午 12 點)
          </li>
          <li>
            若您錯過上述取消預約時間，須待該場次結束後，使得再次使用本系統
          </li>
          <li>若未誠實填寫，本系統和臺南市政府衛生局得取消其預約資格</li>
          <!-- <li>
            Covid-19 疫苗預約
            <span class="link" @click="$router.push('/selfCheck')"
              >資格查詢</span
            >（https://health-reservation.tainan.gov.tw/selfCheck）外縣市暨外籍民眾使用本系統須知
          </li> -->
          <li>
            您在 COVID-19 疫苗接種完畢的 48
            小時內，都無法再次使用本次進行下次疫苗預約
          </li>
          <li>
            長照機構住民符合接種資格者，將由長照機構將自行造冊，再由衛生局安排衛生所或醫療院所到機構接種，不需要自行預約
          </li>
          <li>
            若您想快速查看所有場次，歡迎使用<span
              class="link"
              @click="toQuery()"
              >預約狀況查詢</span
            >（https://vaccine-query.tainan.gov.tw/）
          </li>
          <li>
            對於 COVID-19 疫苗接種有任何疑問，可電洽轄區衛生所為您服務
          </li>
        </ul>
      </v-card-text>
      <v-card-title
        >外縣市暨外籍民眾、本市6個月嬰幼童使用本系統須知</v-card-title
      >

      <v-card-text>
        <ul>
          <li>
            設籍於外縣市或他國之民眾、以及本市6個月嬰幼童若欲使用本系統預約，請先
            <span class="link" @click="toGoogleForm()">填寫表單</span>
            (https://p.tainan.gov.tw/u/x7tp) 進行申請
          </li>
          <li>
            臺南市政府將於每日上午 9 點將過去 24
            小時申請之名單更新至系統，更新成功之外縣市及外籍民眾得於每日上午 10
            點後開始使用本系統<br />
            (舉例: 2022/01/24 上午 7 點填寫完畢者，2022/01/24 上午 10
            點後即可開始使用預約系統； 2022/01/24 上午 9 點 30
            分填寫完畢者，2022/01/25 上午 10 點後方可開始使用預約系統)
          </li>

          <li>
            若因為民眾自行填寫資料錯誤，致使無法使用系統或更新失敗，臺南市政府不負任何責任，請再次填寫表單
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd">
      <v-card-title>接種預約</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            v-model="form.dose"
            :items="[
              {
                text: `第 1 劑`,
                value: '1',
              },
              {
                text: `第 2 劑`,
                value: '2',
              },
            ]"
            :rules="[(v) => !!v || `需填寫預約劑別`]"
            label="預約劑別"
            required
            dense
            outlined
            validate-on-blur
            persistent-hint
          ></v-select>
          <v-text-field
            outlined
            class="text-left"
            v-model="form.id"
            :rules="[
              (v) => !!v || `身分證字號為必填項目`,
              (v) =>
                /^[A-Za-z0-9]{10}$/.test(v) ||
                `身分證字號格式錯誤，含英文共 10 碼`,
            ]"
            validate-on-blur
            persistent-hint
            hint="含英文共 10 碼"
            label="身分證字號"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.birth"
            :rules="[
              (v) => !!v || `出生年月日為必填項目`,
              (v) =>
                /^\d?\d{6}$/.test(v) ||
                `出生年月日格式錯誤，民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411`,
            ]"
            validate-on-blur
            label="出生年月日"
            persistent-hint
            hint="民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.tel"
            :rules="[
              (v) => !!v || `手機號碼為必填項目`,
              (v) =>
                /^09\d{8}$/.test(v) ||
                `手機號碼格式錯誤，僅接受手機門號共 10 碼`,
            ]"
            validate-on-blur
            label="手機號碼"
            persistent-hint
            hint="僅接受手機門號共 10 碼"
            required
            clearable
            dense
          ></v-text-field>
          <v-checkbox
            class="mt-2"
            v-model="checkbox"
            :rules="[(v) => v]"
            label="我已詳細閱讀預約資格與注意事項"
          ></v-checkbox>
          <v-row class="">
            <v-col>
              <v-btn color="success" @click="validate('reserve')" block x-large>
                <v-icon>mdi-calendar-check</v-icon> 接種預約
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="warning" @click="validate('check')" block x-large>
                <v-icon>mdi-calendar-refresh</v-icon> 查詢/取消預約
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";

const stop = false;

const expired = 24 * 60 * 60 * 1000;

export default {
  name: "Home",
  data: () => ({
    valid: true,
    checkbox: false,
    form: {
      dose: "",
      id: "",
      birth: "",
      tel: "",
    },
  }),
  created() {
    this.form = ls.get("form") || this.form;
  },
  methods: {
    toQuery() {
      window.open("https://vaccine-query.tainan.gov.tw/", "_blank");
    },
    to1922() {
      window.open("https://1922.gov.tw/", "_blank");
    },
    toGoogleForm() {
      window.open("https://p.tainan.gov.tw/u/x7tp", "_blank");
    },
    getPastDate(day) {
      return dayjs()
        .add(day * -1, "days")
        .format("YYYY年MM月DD日");
    },
    async validate(action) {
      if (stop) {
        alert("目前網站維護中，請稍後再試！");
        return;
      }

      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.form.id = this.form.id.toUpperCase();
        this.form.birth = String(parseInt(this.form.birth));
        ls.set("form", this.form, expired);
        // let res = (await axios.get(`/covid/population`, { params: this.form })).data;
        let res = (await axios.post(`/covid/population`, this.form)).data;

        if (res.token) {
          ls.set("token", res.token, expired);
          if (res.data.接種時間) action = "check";

          switch (action) {
            case "check":
              this.$gtag.event("按鈕-查詢/取消預約");
              this.$router.push({
                name: "CovidMyReservation",
                params: { propsReservation: res.data },
              });
              break;
            case "reserve":
              this.$gtag.event("按鈕-預約接種");
              this.$router.push({
                name: "CovidReserve",
              });
              break;
          }
        } else {
          this.$toast.error(
            "1. 您輸入的資料有誤，請重新確認資料。\n2. 您的網路連線不佳，請重新確認裝置網路環境。\n3. 若您為外縣市的民眾，請先詳閱首頁的外縣市民眾使用本系統須知。\n4. 您所選擇的劑別與您過往的接種紀錄不合。"
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
ol > li {
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  color: #c75213;
}
ul > li {
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}
.link {
  color: #c75213;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .v-text-field input {
  text-align: left;
}
</style>
