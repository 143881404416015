<template>
  <v-container class="home">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title>接種資格</v-card-title>
      <v-card-text class="list">
        <div class="text-subtitle-1">第一階段(112.10.2起)</div>
        <ol>
          <li>醫事及衛生防疫相關人員</li>
          <li>65歲以上長者</li>
          <li>安養、養護、長期照顧(服務)等機構之受照顧者及其所屬工作人員</li>
          <li>6個月以上至國小入學前幼兒</li>
          <li>孕婦</li>
          <li>
            具有潛在疾病者，包括高風險慢性病人、BMI≧30者、罕見疾病患者及重大傷病患者
          </li>
          <li>6個月內嬰兒之父母</li>
          <li>幼兒園托育人員、托育機構專業人員及居家托育人員(保母)</li>
          <li>國小、國中、高中、高職、五專一至三年級學生等</li>
          <li>禽畜相關及動物防疫人員</li>
        </ol>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">第二階段(112.11.1起)</div>
        <ol>
          <li>50～64歲無高風險慢性病之成人</li>
        </ol>
      </v-card-text>
      <v-card-title>注意事項</v-card-title>

      <v-card-text>
        <ul>
          <li>發燒或正患有急性中重疾病者，宜待病情穩定後再接種</li>
          <li>出生未滿6個月，因無使用效益及安全性等臨床資料，故不予接種</li>
          <li>
            先前接種本疫苗六週內曾發生Guillain-Barré症候群（GBS）者，宜請醫師評估
          </li>
          <li>其他經醫師評估不適合接種者，不予接種</li>
          <li>已知對疫苗的成分有過敏者，不予接種。</li>
          <li>過去注射曾經發生嚴重不良反應者，不予接種。</li>
          <li>
            有關特殊身分別（如醫事人員、托育人員）請攜帶相關證明文件佐證身分，該系統僅提供預約服務，接種現場會核對是否符合公費資格，再行接種
          </li>
        </ul>
      </v-card-text>
      <v-card-title>
        外縣市暨外籍民眾、本市6個月嬰幼童使用本系統須知
      </v-card-title>

      <v-card-text>
        <ul>
          <li>
            外籍人士需持有居留證(包含外交官員證、國際機構官員證及外國機構官員證)，並符合接種資格
          </li>
          <li>
            本系統僅提供第一劑流感疫苗預約，如有第二劑接種需求請直接至合約醫療院所或衛生所洽詢
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd">
      <v-card-title>接種預約</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            outlined
            class="text-left"
            v-model="form.id"
            :rules="[
              (v) => !!v || `身分證字號為必填項目`,
              (v) =>
                /^[A-Za-z0-9]{10}$/.test(v) ||
                `身分證字號格式錯誤，含英文共 10 碼`,
            ]"
            validate-on-blur
            persistent-hint
            hint="含英文共 10 碼"
            label="身分證字號"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.birth"
            :rules="[
              (v) => !!v || `出生年月日為必填項目`,
              (v) =>
                /^\d?\d{6}$/.test(v) ||
                `出生年月日格式錯誤，民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411`,
            ]"
            validate-on-blur
            label="出生年月日"
            persistent-hint
            hint="民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.tel"
            :rules="[
              (v) => !!v || `手機號碼為必填項目`,
              (v) =>
                /^09\d{8}$/.test(v) ||
                `手機號碼格式錯誤，僅接受手機門號共 10 碼`,
            ]"
            validate-on-blur
            label="手機號碼"
            persistent-hint
            hint="僅接受手機門號共 10 碼"
            required
            clearable
            dense
          ></v-text-field>
          <v-checkbox
            class="mt-2"
            v-model="checkbox"
            :rules="[(v) => v]"
            label="我已詳細閱讀預約資格與注意事項"
          ></v-checkbox>
          <v-row class="">
            <v-col>
              <v-btn color="success" @click="validate('reserve')" block x-large>
                <v-icon>mdi-calendar-check</v-icon> 接種預約
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="warning" @click="validate('check')" block x-large>
                <v-icon>mdi-calendar-refresh</v-icon> 查詢/取消預約
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";

const stop = false;

const expired = 24 * 60 * 60 * 1000;

export default {
  name: "Home",
  data: () => ({
    valid: true,
    checkbox: false,
    form: {
      id: "",
      birth: "",
      tel: "",
    },
  }),
  created() {
    this.form = ls.get("form") || this.form;
  },
  methods: {
    // toQuery() {
    //   window.open("https://vaccine-query.tainan.gov.tw/", "_blank");
    // },

    getPastDate(day) {
      return dayjs()
        .add(day * -1, "days")
        .format("YYYY年MM月DD日");
    },
    async validate(action) {
      if (stop) {
        alert("目前網站維護中，請稍後再試！");
        return;
      }

      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.form.id = this.form.id.toUpperCase();
        this.form.birth = String(parseInt(this.form.birth));
        ls.set("form", this.form, expired);

        try {
          await axios.post(`/influenza/qualify`, this.form);
          this.$router.push({
            name: "InfluenzaMyReservation",
            params: {
              toReserve: action == "reserve",
            },
          });
        } catch (error) {
          this.$toast.error(error.response.data);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-subtitle-1 {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 10px;
  color: #c75213;
}
ol > li {
  font-size: 16px;
  // font-weight: bold;
  line-height: 26px;
  // color: #c75213;
}
ul > li {
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}
.link {
  color: #c75213;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .v-text-field input {
  text-align: left;
}
</style>
