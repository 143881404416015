import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import ls from "expired-localstorage";
import { LoadingPlugin } from "vuetify-loading-overlay";
import VueGtag from "vue-gtag";

import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

import { APIURL } from "@/../env.js";

axios.interceptors.request.use(
  function(config) {
    let token = ls.get("token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    config.url = config.alone ? config.url : APIURL + config.url;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          location.href = "/";
          break;
        default:
          console.log(error.message);
      }
    }
    return Promise.reject(error);
  }
);

Vue.use(Toast, {
  position: "bottom-center",
  timeout: 10000,
  pauseOnHover: false,
  hideProgressBar: false,
  bodyClassName: ["toast-text"],
});

Vue.use(LoadingPlugin, {
  spinnerProps: {
    color: "#e3814c",
    width: "7",
    size: 70,
  },
  overlayProps: {},
});

Vue.use(
  VueGtag,
  {
    config: { id: "UA-78248788-12" },
  },
  router
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
